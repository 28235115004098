/* SimpleFooter.css */

.footer {
  background-color: #004861; /* Cor de fundo do footer */
  color: #fff; /* Cor do texto dentro do footer */
  padding: 0.1rem 0; /* Padding reduzido para tornar o footer mais compacto */
  display: flex; /* Garantir que o footer é um contêiner flexível */
  flex-direction: column; /* Organizar os elementos em uma coluna */
  align-items: center; /* Centralizar os itens horizontalmente */
  justify-content: center; /* Centralizar os itens verticalmente */
}

.footer h5, .footer p {
  color: #fff;
  margin: 0;
  padding: 0.1rem 0; /* Padding reduzido nos textos */
}

.footer h5 {
  font-size: 1.25rem; /* Aumenta o tamanho do texto "ENTRE EM CONTATO" */
}

.footer .contact-info {
  font-size: 0.84rem !important; /* Aumenta o tamanho da fonte das informações de contato */
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-wrapper a {
  display: inline-block; /* Assegura que os ícones sejam exibidos inline */
}

ul.list-unstyled {
  padding: 0;
  list-style: none;
}

ul.list-unstyled li a {
  color: #fff;
  text-decoration: none;
}

ul.list-unstyled li a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer h5, .footer p {
    font-size: 0.75rem;
  }
  .btn-wrapper a {
    font-size: 1.5rem; /* Ajuste do tamanho dos ícones para telas menores */
  }
  .contact-info {
    font-size: 0.875rem; /* Ajuste do tamanho da fonte das informações de contato para telas menores */
  }
}
