iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
}

main {
    overflow: hidden;
}

#root {
    min-height: 100%;
    position: relative;
}
